
import { Component, Vue } from 'vue-property-decorator';
import { Gopage } from '@/mixins/gopage';
import BottomModule from '@/components/bottomModule/bottomModule.vue';

@Component({
  components: { BottomModule },
  mixins: [Gopage],
})
export default class Solution extends Vue {
  private activeName: any = 'second';
  private dropdownIcon: any = 'el-icon-s-unfold';

  /**
   * @desc Vue 创建数据生命周期，获取验证码
   * @param1 暂无
   * @returns 暂无
   */
  created() {}

  // beforeDestroy() {
  //   window.scrollTo({ top: 0 });
  // }

  visibleChange(e: any) {
    this.dropdownIcon = e ? 'el-icon-close' : 'el-icon-s-unfold';
  }

  handleToProduct(data: any) {
    this.$router.push({ name: 'ProductCenter', params: { sysType: data, router: 'solution' } });
  }
}
